import { simplifiedNotes, modes, modeIntervals, getInterval, createScale, getTriadName, getTriadsInKey, triadRomanNumerals, shift, modesByBrightness, getCircleOfFifths } from "./music-theory";
import { getOptimalRepresentation } from "./optimal-representation";
import { generateChordProgression } from "./chord-progressions";
var randomFromArray = function (array) { return array[Math.floor(Math.random() * array.length)]; };
var toTitleCase = function (str) { return str[0].toUpperCase() + str.slice(1); };
var createScaleData = function (note, mode) {
    var selectedKeyWithSharps = createScale(note, modeIntervals[modes.indexOf(mode)]);
    var selectedKey = getOptimalRepresentation(selectedKeyWithSharps);
    var triadsInSelectedKey = getTriadsInKey(selectedKey);
    var triads = triadsInSelectedKey.map(function (triad) {
        var triadIntervals = triad.map(function (note) { return getInterval(triad[0], note); });
        var triadName = getTriadName(triadIntervals);
        var triadRomanNumeral = triadRomanNumerals[triadName || ""](triadsInSelectedKey.indexOf(triad));
        return {
            name: triad[0] + " " + triadName,
            numeral: triadRomanNumeral,
            notes: triad,
            type: triadName || triadIntervals
        };
    });
    return {
        name: selectedKey[0] + " " + toTitleCase(mode),
        notes: selectedKey,
        circleOfFifths: getOptimalRepresentation(getCircleOfFifths(selectedKey[0], mode)),
        triads: triads,
        generateChordProgression: function () { return generateChordProgression(mode); }
    };
};
var startGame = function () {
    var currentNote = randomFromArray(simplifiedNotes);
    var currentMode = randomFromArray(modes);
    debugger;
    return {
        getScale: function () { return createScaleData(currentNote, currentMode); },
        moveFifth: function (steps) {
            currentNote = shift(currentNote, steps * 7);
        },
        moveBrightness: function (steps) {
            var currentBrightness = modesByBrightness.indexOf(currentMode);
            var newBrightness = currentBrightness + steps;
            currentMode = modesByBrightness[(7 + newBrightness) % 7];
            currentNote = shift(currentNote, Math.floor(newBrightness / 7));
        }
    };
};
export { startGame };
