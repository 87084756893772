import * as piano from "./virtual-piano";
import { setupMidiListener } from "./midi";
import { startGame } from "./game";
(function () {
    var _a, _b, _c, _d, _e, _f;
    if (!piano.createPiano()) {
        // I dunno why this loads twice, so just ignore the second time
        return;
    }
    var scaleElement = document.getElementById("scale-name");
    var keySigElement = document.getElementById("key-signature");
    var writtenChordElements = document.querySelectorAll("#chords .chord");
    var notationChordElements = document.querySelectorAll("#notation .crotchet");
    var fifthsNoteElements = document.querySelectorAll("#circle-of-fifths .note");
    var modeArrowElement = document.getElementById("mode-arrow");
    var highlightPosition = function (position) {
        for (var i = 0; i < writtenChordElements.length; i++) {
            if (i === position) {
                writtenChordElements[i].classList.add("active");
                notationChordElements[i].classList.add("active");
            }
            else {
                writtenChordElements[i].classList.remove("active");
                notationChordElements[i].classList.remove("active");
            }
        }
    };
    var setKeySig = function (scaleNotes) {
        keySigElement.innerHTML = '';
        for (var n = 0; n < scaleNotes.length; n++) {
            var note = scaleNotes[n];
            if (note.length > 1) {
                var div = document.createElement('div');
                div.classList.add('note');
                div.classList.add(note[0]);
                div.classList.add(note.endsWith('♭') ? 'flat' : 'sharp');
                keySigElement.append(div);
            }
        }
    };
    var setCircleNotes = function (scaleNotes) {
        for (var i = 0; i < fifthsNoteElements.length; i++) {
            var note = scaleNotes[i];
            fifthsNoteElements[i].innerHTML = note;
        }
    };
    var setArrowPosition = function (scaleName) {
        modeArrowElement.className = 'arrow up ' + scaleName.split(' ')[1].toLowerCase();
    };
    var game = startGame();
    var loadScale = function () {
        var scale = game.getScale();
        scaleElement.innerText = scale.name;
        setKeySig(scale.notes);
        setCircleNotes(scale.circleOfFifths);
        setArrowPosition(scale.name);
        var progression = [];
        var newProgression = function () {
            progression = scale.generateChordProgression();
            for (var i = 0; i < progression.length; i++) {
                var triad = scale.triads[progression[i] - 1];
                writtenChordElements[i].innerHTML = '<div class="name">' + triad.name + '</div>' + '<div class="numeral">' + triad.numeral + '</div>';
                notationChordElements[i].className = 'crotchet position-' + (i + 1) + ' ' + triad.notes[0][0];
            }
        };
        var askForTriad = function (position, createNewOnHit) {
            var triad = scale.triads[progression[position] - 1];
            piano.setHighlightNoteColors("in-next-chord", triad.notes);
            piano.onNotesHit(triad.notes, function () {
                if (createNewOnHit) {
                    newProgression();
                }
                highlightPosition(position);
                piano.setHighlightNoteColors("in-chord", triad.notes);
                if (position === progression.length - 1) {
                    askForTriad(0, true);
                }
                else {
                    askForTriad(position + 1, false);
                }
            });
        };
        piano.setHighlightNoteColors("in-scale", scale.notes);
        piano.setHighlightNoteColors("in-chord", []);
        piano.setNoteNumerals(scale.notes, scale.triads.map(function (triad) { return triad.numeral; }));
        newProgression();
        highlightPosition(-1);
        askForTriad(0, false);
    };
    loadScale();
    (_a = document.getElementById("fifth-up")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () {
        game.moveFifth(1);
        loadScale();
    });
    (_b = document.getElementById("fifth-down")) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function () {
        game.moveFifth(-1);
        loadScale();
    });
    (_c = document.getElementById("mode-brighten-parallel")) === null || _c === void 0 ? void 0 : _c.addEventListener("click", function () {
        game.moveBrightness(1);
        loadScale();
    });
    (_d = document.getElementById("mode-brighten-relative")) === null || _d === void 0 ? void 0 : _d.addEventListener("click", function () {
        game.moveBrightness(1);
        game.moveFifth(-1);
        loadScale();
    });
    (_e = document.getElementById("mode-darken-parallel")) === null || _e === void 0 ? void 0 : _e.addEventListener("click", function () {
        game.moveBrightness(-1);
        loadScale();
    });
    (_f = document.getElementById("mode-darken-relative")) === null || _f === void 0 ? void 0 : _f.addEventListener("click", function () {
        game.moveBrightness(-1);
        game.moveFifth(1);
        loadScale();
    });
    setupMidiListener(piano.setNotePressed);
})();
